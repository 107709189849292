import React, { useEffect, useState } from 'react';
import { Container, Table, FormControl, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate to handle navigation
import { getAllSpecies } from '../../services/speciesService';  // Import species service
import '../../styles.css';

const Species = () => {
  const [speciesData, setSpeciesData] = useState([]);
  const [filteredSpecies, setFilteredSpecies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate function

  useEffect(() => {
    const fetchSpeciesData = async () => {
      try {
        const data = await getAllSpecies();
        if (data.species && Array.isArray(data.species)) {
          setSpeciesData(data.species);
          setFilteredSpecies(data.species);
        } else {
          setError('Unexpected data format');
        }
      } catch (error) {
        console.error('Error fetching species data:', error);
        setError('Error fetching species data');
      } finally {
        setLoading(false);
      }
    };

    fetchSpeciesData();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const results = speciesData.filter((species) =>
        Object.values(species).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredSpecies(results);
    } else {
      setFilteredSpecies(speciesData);
    }
  }, [searchTerm, speciesData]);

  const handleRowClick = (speciesId) => {
    navigate(`/species-details?speciesId=${speciesId}`);
  };

  if (loading) {
    return (
      <Container className="content d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return <Container className="content"><p>{error}</p></Container>;
  }

  return (
    <Container className="content">
      <h1>Species Page</h1>
      <div className="d-flex mb-3">
        <FormControl
          type="text"
          placeholder="Search"
          className="me-2"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Species Breed</th>
            <th>Binomial Name</th>
            <th>Other Names</th>
            <th>Short Description</th>
          </tr>
        </thead>
        <tbody>
          {filteredSpecies.map((species) => (
            <tr
              key={species.speciesId}
              onClick={() => handleRowClick(species.speciesId)}
              style={{ cursor: 'pointer' }}
            >
              <td>{species.speciesBreed}</td>
              <td>{species.binomialName}</td>
              <td>{species.otherNames}</td>
              <td>{species.descriptionShort}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Species;
