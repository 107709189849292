import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { auth } from '../../firebaseConfig';
import { updateProfile, onAuthStateChanged } from 'firebase/auth';
import '../../styles.css';

const Profile = () => {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [creationTime, setCreationTime] = useState('');
  const [lastSignInTime, setLastSignInTime] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setEmail(user.email);
      setPhoneNumber(user.phoneNumber || '');
      setDisplayName(user.displayName || '');
      setCreationTime(user.metadata.creationTime);
      setLastSignInTime(user.metadata.lastSignInTime);
    } else {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          setEmail(user.email);
          setPhoneNumber(user.phoneNumber || '');
          setDisplayName(user.displayName || '');
          setCreationTime(user.metadata.creationTime);
          setLastSignInTime(user.metadata.lastSignInTime);
        }
      });

      return () => unsubscribe();
    }
  }, []);

  const handleSave = () => {
    const user = auth.currentUser;
    if (user) {
      updateProfile(user, {
        displayName,
        phoneNumber
      }).then(() => {
        setIsEditing(false);
      }).catch((error) => {
        console.error('Error updating profile:', error);
      });
    }
  };

  return (
    <Container className="content">
      <h1 className="mb-5">Profile Page</h1>
      <Form>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label>Username:</Form.Label>
              <Form.Text> {email}</Form.Text>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formDisplayName">
              <Form.Label>Display name</Form.Label>
              <Form.Control
                type="text"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                readOnly={!isEditing}
              />
            </Form.Group>
          </Col>
        </Row>
        
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone number</Form.Label>
              <Form.Control
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                readOnly={!isEditing}
              />
            </Form.Group>
          </Col>
        </Row>
        

        
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formCreationTime">
              <Form.Label>Profile created:</Form.Label>
              <Form.Text> {creationTime}</Form.Text>
            </Form.Group>
          </Col>
        </Row>
        
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formLastSignInTime">
              <Form.Label>Last sign-in:</Form.Label>
              <Form.Text> {lastSignInTime}</Form.Text>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            {isEditing ? (
              <>
                <Button variant="primary" onClick={handleSave} className="me-2">
                  Save
                </Button>
                <Button variant="secondary" onClick={() => setIsEditing(false)}>
                  Cancel
                </Button>
              </>
            ) : (
              <Button variant="primary" onClick={() => setIsEditing(true)}>
                Edit
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default Profile;

