import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import SkuSelectionModal from './SkuSelectionModal'; // Import the modal component
import { updatePackedItems } from '../services/orderService'; // Ensure correct path
import './SkuItem.css'; // Import custom CSS for styling

const SkuItem = ({ producerOrder, sku, skus, handleSkuClick }) => {
  const [orderedItemTotal, setOrderedItemTotal] = useState(0);
  const [packedItemTotal, setPackedItemTotal] = useState('-');
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);

  // Format the display for a SKU
  const formatSkuDisplay = (specificSku) => {
    if (!specificSku) return 'SKU not found';
    const fields = [
      specificSku.skuName,
      specificSku.unitSizeName,
      specificSku.packSizeDetail,
      specificSku.cut,
      specificSku.process
    ];
    return fields.filter(Boolean).join(' - ');
  };

  // Get the ordered SKU details
  const orderedSku = skus.find((item) => item.skuId === sku.skuId);
  const orderedSkuLabel = formatSkuDisplay(orderedSku);

  // Retrieve the packedSku details from producerOrder
  const packedSkuDetails = producerOrder.packedSkus?.find((packedItem) => packedItem.skuId === sku.skuId);
  const packedSkuId = packedSkuDetails?.packedSkuId || null;
  const packedQuantity = packedSkuDetails ? parseFloat(packedSkuDetails.packedQuantity) : null;

  // Fetch the packedSku details from the list of skus
  const packedSku = skus.find((item) => item.skuId === packedSkuId);
  const packedSkuLabel = packedSku ? formatSkuDisplay(packedSku) : '-';

  useEffect(() => {
    if (orderedSku) {
      setOrderedItemTotal(orderedSku.price * sku.quantity);
    }
  }, [orderedSku, sku.quantity]);

  useEffect(() => {
    if (packedSku && !isNaN(packedQuantity)) {
      setPackedItemTotal(packedSku.price * packedQuantity);
    } else {
      setPackedItemTotal('-');
    }
  }, [packedSku, packedQuantity]);

  // Function to handle modal open/close
  const handleModalToggle = () => {
    setShowModal(!showModal);
    setError(null); // Clear any existing errors when toggling the modal
  };

  // Function to handle selecting an SKU from the modal table
  const handleSkuSelect = async (selectedSku, selectedQuantity) => {
    if (!selectedQuantity || parseFloat(selectedQuantity) <= 0) {
      setError('Please enter a valid quantity.');
      return;
    }

    // Prepare the payload for API call
    const payload = {
      orderId: producerOrder.orderId,
      producerOrderId: producerOrder.producerOrderId,
      packedSkus: [
        {
          skuId: sku.skuId,
          packedSkuId: selectedSku.skuId,
          packedQuantity: parseFloat(selectedQuantity),
        },
      ],
    };

    try {
      await updatePackedItems(payload);
      // Update the packed detail states directly
      sku.packedSkuId = selectedSku.skuId;
      sku.packedQuantity = selectedQuantity;
      // Refresh page after 2 seconds
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      setError('Failed to update packed items. Please try again.');
    }
  };

  return (
    <>
      <Row className="g-3 d-flex align-items-stretch mb-2">
        {/* Ordered Section */}
        <Col xs={12} md={6} className="d-flex">
          <Card className="flex-fill rounded" style={{ borderRight: '1px solid #dee2e6', marginBottom: '10px' }}>
            <Card.Body>
              <p>
                <strong>Ordered:</strong>
              </p>
              <p>
                Item:{' '}
                <span
                  onClick={() => handleSkuClick(sku.skuId)}
                  className="mt-2 link-like-input"
                  style={{ color: '#0000ff', cursor: 'pointer' }}
                >
                  {orderedSkuLabel}
                </span>
              </p>
              <p>Quantity: {sku.quantity}</p>
              <p>Item Total: {`$${orderedItemTotal.toFixed(2)}`}</p>
            </Card.Body>
          </Card>
        </Col>

        {/* Packed Section */}
        <Col xs={12} md={6} className="d-flex">
          <Card className="flex-fill rounded" style={{ borderLeft: '1px solid #dee2e6', marginBottom: '10px' }}>
            <Card.Body>
              <p>
                <strong>Packed:</strong>
              </p>
              <p>
                Item:{' '}
                {packedSkuLabel !== '-' ? (
                  <span
                    onClick={() => handleSkuClick(packedSkuId)}
                    className="mt-2 link-like-input"
                    style={{ color: '#0000ff', cursor: 'pointer' }}
                  >
                    {packedSkuLabel}
                  </span>
                ) : (
                  packedSkuLabel
                )}
              </p>
              <p>Quantity: {packedQuantity || '-'}</p>
              <p>Item Total: {packedItemTotal === '-' ? '-' : `$${packedItemTotal.toFixed(2)}`}</p>
              <Button variant="primary" onClick={handleModalToggle}>
                Edit
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Sku Selection Modal */}
      <SkuSelectionModal
        show={showModal}
        handleClose={handleModalToggle}
        skus={skus}
        handleSkuSelect={handleSkuSelect}
        error={error}
      />
    </>
  );
};

export default SkuItem;
