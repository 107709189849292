import { functions } from "../firebaseConfig"; // Assuming firebaseConfig correctly exports initialized Firebase app
import { httpsCallable } from "firebase/functions";

/**
 * Fetches an order by its ID using the callable function 'getOrderById'.
 * @param {string} orderId - The ID of the order to be fetched.
 * @returns {Promise<object>} - The order data if successful.
 * @throws {Error} - Throws an error if the function call fails.
 */
export const getOrderByIdV1 = async (orderId) => {
    try {
        const getOrderByIdCallable = httpsCallable(functions, "getOrderById");
        const response = await getOrderByIdCallable({ orderId });

        if (response.data && response.data.order) {
            return response.data.order;
        } else {
            throw new Error("Order data not found in the response");
        }
    } catch (error) {
        console.error("Error fetching order:", error);
        throw error;
    }
};

export const getOrderById = async (orderId) => {
    try {
        const getOrderByIdCallable = httpsCallable(functions, "getOrderByIdV2");
        const response = await getOrderByIdCallable({ orderId });

        if (response.data && response.data.order) {
            return response.data.order;
        } else {
            throw new Error("Order data not found in the response");
        }
    } catch (error) {
        console.error("Error fetching order:", error);
        throw error;
    }
};

export const getOrders = async () => {
    const getOrdersCallable = httpsCallable(functions, "getOrders");
    // Pass the object with the detailed property set to true
    const response = await getOrdersCallable({ detailed: true });
    const orders = response.data;
    return orders;
};

// Define the updateOrderV2 service function
export const updateOrderV2 = async (orderId, updatedOrder) => {
    try {
        // Create a reference to the Firebase Cloud Function
        const updateOrderV2Function = httpsCallable(functions, "updateOrderV2");

        // Call the Cloud Function and pass the data
        const response = await updateOrderV2Function({ orderId, updatedOrder });

        if (response.data.success) {
            return true;
        } else {
            console.error("Failed to update order:", response.data.message);
            return false;
        }
    } catch (error) {
        console.error("Error calling updateOrderV2:", error);
        return false;
    }
};

// Define the updatePackedItems service
export const updatePackedItems = async ({ orderId, producerOrderId, packedSkus }) => {
    try {
        // Reference the callable function 'updatePackedItems' from your backend
        const updatePackedItemsCallable = httpsCallable(functions, "updatePackedItems");

        // Call the backend function with the required data
        const response = await updatePackedItemsCallable({
            orderId,
            producerOrderId,
            packedSkus,
        });

        // Handle successful response
        if (response.data.result.success) {
            return response.data.result;
        } else {
            // Handle unsuccessful response
            throw new Error("Failed to update packed items.");
        }
    } catch (error) {
        // Handle errors from the function call
        throw error;
    }
};

export const getOrderSummary = async (items, postcode) => {
    try {
        const getOrderSummaryCallable = httpsCallable(functions, "getOrderSummaryV2");
        const response = await getOrderSummaryCallable({ items, postcode });
        return response.data.orderSummary;
    } catch (error) {
        console.error("Error generating order summary:", error);
        throw error;
    }
};

export const updateOrderStatus = async (orderId, orderStatus, paymentMethod) => {
    try {
        const updateOrderStatus = httpsCallable(functions, "updateOrderStatus");
        const response = await updateOrderStatus({
            orderId,
            updatedOrder: {
                orderStatus,
                paymentMethod,
            },
        });

        if (response?.data?.success) {
            console.log("Order updated successfully:", response.data.message);
            return response.data;
        } else {
            throw new Error(response.data.message || "Failed to update order");
        }
    } catch (error) {
        console.error("Error updating order status:", error);
        throw error;
    }
};

export const updateProducerOrderStatus = async (orderId, producerOrderId, producerOrderStatus) => {
    try {
        // Create a reference to the Firebase callable function
        const updateProducerOrderStatus = httpsCallable(functions, "updateProducerOrderStatus");

        // Call the function with the required parameters
        const response = await updateProducerOrderStatus({
            orderId,
            producerOrderId,
            producerOrderStatus,
        });

        // Check the response from the callable function
        if (response?.data?.success) {
            console.log("Producer order status updated successfully:", response.data.message);
            return response.data;
        } else {
            throw new Error(response.data.message || "Failed to update producer order status");
        }
    } catch (error) {
        console.error("Error updating producer order status:", error);
        throw error;
    }
};

// Define the service function to call updateFinalOrderSummary
export const updateFinalOrderSummary = async (orderId) => {
    try {
        // Create a reference to the Firebase callable function
        const updateFinalOrderSummary = httpsCallable(functions, "updateFinalOrderSummary");

        // Call the function with the required parameters
        const response = await updateFinalOrderSummary({ orderId });

        // Check if the function returned success
        if (response?.data?.success) {
            console.log("Final order summary updated successfully:", response.data);
            return response.data; // Return the final order summary
        } else {
            throw new Error(response.data?.message || "Failed to update the final order summary.");
        }
    } catch (error) {
        console.error("Error updating final order summary:", error);
        throw error; // Rethrow the error for further handling
    }
};
