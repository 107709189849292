import React, { useState } from "react";
import { Card, Row, Col, Button, Alert, Spinner } from "react-bootstrap";
import Select from "react-select";
import { updateOrderStatus } from "../services/orderService"; // Import the updateOrderV2 service

const OrderOverviewComponent = ({
    orderDetails,
    deliveryAddress,
    buyerContactName,
    buyerTradingName,
    buyerCompanyName,
    createdAt,
    orderId,
    orderStatus,
    updatedAt,
    onOrderStatusChange,
}) => {
    // Options for order status
    const orderStatusOptions = [
        { value: "BASKET", label: "BASKET" },
        { value: "CHECKOUT", label: "CHECKOUT" },
        { value: "PAYMENT_RECEIVED", label: "PAYMENT_RECEIVED" },
        { value: "PAYMENT_FAILED", label: "PAYMENT_FAILED" },
        { value: "PROCESSING", label: "PROCESSING" },
        { value: "COMPLETE", label: "COMPLETE" },
    ];

    // Options for payment method
    const paymentMethodOptions = [
        { value: "CREDIT_CARD", label: "CREDIT_CARD" },
        { value: "INVOICE", label: "INVOICE" },
    ];

    // Local state to manage the selected status, editing mode, loading state, and alerts
    const [currentStatus, setCurrentStatus] = useState(orderStatus);
    const [paymentMethod, setPaymentMethod] = useState(orderDetails?.paymentMethod);
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [alert, setAlert] = useState({ type: "", message: "" });

    // Handle status change from the dropdown
    const handleStatusChange = (selectedOption) => {
        setCurrentStatus(selectedOption.value);
    };

    // Handle payment method change from the dropdown
    const handlePaymentMethodChange = (selectedOption) => {
        setPaymentMethod(selectedOption.value);
    };

    // Find the selected order status option based on the currentStatus value
    const selectedStatus = orderStatusOptions.find((option) => option.value === currentStatus);
    const selectedPaymentMethod = paymentMethodOptions.find((option) => option.value === paymentMethod);

    // Handle Edit button click
    const handleEdit = () => {
        setIsEditing(true);
        setAlert({ type: "", message: "" }); // Clear any existing alerts
    };

    // Handle Cancel button click
    const handleCancel = () => {
        setCurrentStatus(orderStatus); // Reset to the original order status
        setIsEditing(false);
        setAlert({ type: "", message: "" }); // Clear any existing alerts
    };

    // Handle Save button click
    const handleSave = async () => {
        setIsSaving(true);
        setAlert({ type: "", message: "" }); // Clear any existing alerts
        try {
            await updateOrderStatus(orderId, currentStatus, paymentMethod);
            setAlert({ type: "success", message: "Updated successfully!" });
            setIsEditing(false); // Exit edit mode
            // Call the callback with the updated order
            const updatedOrder = { ...orderDetails, orderStatus: currentStatus, paymentMethod }; // Create a new order object
            onOrderStatusChange(updatedOrder); // Notify parent about the change
        } catch (error) {
            console.error("Failed to update status:", error);
            setAlert({ type: "danger", message: "Failed to update order status. Please try again." });
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <Card className="mb-3">
            <Card.Header>Overview</Card.Header>
            <Card.Body>
                <Row className="g-3 d-flex align-items-stretch">
                    <Col xs={12} md={6} className="d-flex">
                        <Card className="flex-fill rounded" style={{ borderRight: "1px solid #dee2e6" }}>
                            <Card.Body>
                                <p>
                                    <strong>Contact Name:</strong> {buyerContactName}
                                </p>                                
                                <p>
                                    <strong>Trading Name:</strong> {buyerTradingName}
                                </p>
                                <p>
                                    <strong>Company Name:</strong> {buyerCompanyName}
                                </p>
                                <p>
                                    <strong>Address Line 1:</strong> {deliveryAddress?.addressLine1}
                                </p>
                                <p>
                                    <strong>Address Line 2:</strong> {deliveryAddress?.addressLine2}
                                </p>
                                <p>
                                    <strong>City:</strong> {deliveryAddress?.city}
                                </p>
                                <p>
                                    <strong>Region:</strong> {deliveryAddress?.region}
                                </p>
                                <p>
                                    <strong>Postcode:</strong> {deliveryAddress?.postcode}
                                </p>
                                <p>
                                    <strong>Country:</strong> {deliveryAddress?.country}
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} md={6} className="d-flex">
                        <Card className="flex-fill rounded" style={{ borderLeft: "1px solid #dee2e6" }}>
                            <Card.Body>
                                <div className="d-flex align-items-center mb-2">
                                    <strong className="me-2">Order Status:</strong>
                                    <Select
                                        options={orderStatusOptions}
                                        value={selectedStatus}
                                        onChange={handleStatusChange}
                                        className="flex-grow-1"
                                        isDisabled={!isEditing} // Disable the select when not in edit mode
                                    />
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                    <strong className="me-2">Payment Method:</strong>
                                    <Select
                                        options={paymentMethodOptions}
                                        value={selectedPaymentMethod}
                                        onChange={handlePaymentMethodChange}
                                        className="flex-grow-1"
                                        isDisabled={!isEditing} // Disable the select when not in edit mode
                                    />
                                </div>
                                <p>
                                    <strong>Order ID:</strong> {orderId}
                                </p>
                                <p>
                                    <strong>Created At:</strong> {new Date(createdAt).toLocaleString()}
                                </p>
                                <p>
                                    <strong>Updated At:</strong> {new Date(updatedAt).toLocaleString()}
                                </p>

                                {/* Display Edit, Save, and Cancel buttons */}
                                {!isEditing ? (
                                    <Button variant="secondary" onClick={handleEdit} className="mt-2">
                                        Edit
                                    </Button>
                                ) : (
                                    <>
                                        <Button variant="danger" onClick={handleCancel} className="mt-2 me-2">
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={handleSave}
                                            className="mt-2"
                                            disabled={isSaving}
                                        >
                                            {isSaving ? (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                "Save"
                                            )}
                                        </Button>
                                    </>
                                )}

                                {/* Display an alert for success or error */}
                                {alert.message && (
                                    <Alert variant={alert.type} className="mt-3">
                                        {alert.message}
                                    </Alert>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default OrderOverviewComponent;
