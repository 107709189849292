import React from 'react';
import { Table } from 'react-bootstrap';

const OrderDetailsTable = ({ order }) => {
  const twoColumnLeftStyle = {
    fontWeight: 'bold',
    width: '20%', // Smaller width for left column
  };

  const twoColumnRightStyle = {
    width: '80%', // Remaining width for right column
  };

  const producerOrderColumnStyle = {
    width: '20%', // Adjusted to ensure even width across all columns
  };

  return (
    <div style={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
      <h5>Order Details</h5>

      {/* General Order Info */}
      <h6>General Information</h6>
      <Table bordered hover size="sm">
        <tbody>
          <tr>
            <td style={twoColumnLeftStyle}>Order ID</td>
            <td style={twoColumnRightStyle}>{order.orderId}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>Buyer Contact Name</td>
            <td style={twoColumnRightStyle}>{order.orderDetails.buyerContactName}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>Buyer Trading Name</td>
            <td style={twoColumnRightStyle}>{order.orderDetails.buyerTradingName}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>Buyer Company Name</td>
            <td style={twoColumnRightStyle}>{order.orderDetails.buyerCompanyName}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>Order Status</td>
            <td style={twoColumnRightStyle}>{order.orderStatus}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>Created At</td>
            <td style={twoColumnRightStyle}>{new Date(order.createdAt).toLocaleString()}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>Updated At</td>
            <td style={twoColumnRightStyle}>{new Date(order.updatedAt).toLocaleString()}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>Payment Method</td>
            <td style={twoColumnRightStyle}>{order.orderDetails.paymentMethod}</td>
          </tr>



        </tbody>
      </Table>

      {/* Delivery Address */}
      <h6>Delivery Address</h6>
      <Table bordered hover size="sm">
        <tbody>
          <tr>
            <td style={twoColumnLeftStyle}>Address Line 1</td>
            <td style={twoColumnRightStyle}>{order.deliveryAddress.addressLine1}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>Address Line 2</td>
            <td style={twoColumnRightStyle}>{order.deliveryAddress.addressLine2 || 'N/A'}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>City</td>
            <td style={twoColumnRightStyle}>{order.deliveryAddress.city}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>Region</td>
            <td style={twoColumnRightStyle}>{order.deliveryAddress.region}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>Postcode</td>
            <td style={twoColumnRightStyle}>{order.deliveryAddress.postcode}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>Country</td>
            <td style={twoColumnRightStyle}>{order.deliveryAddress.country}</td>
          </tr>
        </tbody>
      </Table>

      {/* Shipping Information & Order Totals */}
      <h6>Shipping Information & Order Totals</h6>
      <Table bordered hover size="sm">
        <tbody>
          <tr>
            <td style={twoColumnLeftStyle}>Shipping Zone</td>
            <td style={twoColumnRightStyle}>{order.orderSummary.shipping.shippingZone}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>Shipping Mode</td>
            <td style={twoColumnRightStyle}>{order.orderSummary.shipping.shippingMode}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>Total Item Cost</td>
            <td style={twoColumnRightStyle}>${order.orderSummary.totalItemCost.toFixed(2)}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>Shipping Cost</td>
            <td style={twoColumnRightStyle}>${order.orderSummary.shipping.shippingCost.toFixed(2)}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>GST on Shipping</td>
            <td style={twoColumnRightStyle}>${order.orderSummary.shipping.gstOnShipping.toFixed(2)}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>Platform Fee</td>
            <td style={twoColumnRightStyle}>${order.orderSummary.platform.platformFee.toFixed(2)}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>GST on Platform Fee</td>
            <td style={twoColumnRightStyle}>${order.orderSummary.platform.gstOnPlatformFee.toFixed(2)}</td>
          </tr>
          <tr>
            <td style={twoColumnLeftStyle}>Order Total</td>
            <td style={twoColumnRightStyle}>${order.orderSummary.orderTotal.toFixed(2)}</td>
          </tr>
        </tbody>
      </Table>

      {/* Producer Orders */}
      {order.orderDetails.producerOrders.map((producerOrder, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
          <h6>Seller: {producerOrder.seller}</h6>
          {/* Producer Order Dates */}
          <Table bordered hover size="sm">
            <tbody>
            <tr>
                <td style={twoColumnLeftStyle}>Status</td>
                <td style={twoColumnRightStyle}>{producerOrder.producerOrderStatus || 'N/A'}</td>
              </tr>
              <tr>
                <td style={twoColumnLeftStyle}>Substitutions required</td>
                <td style={twoColumnRightStyle}>{producerOrder.substutionsRequired || 'N/A'}</td>
              </tr>
              <tr>
                <td style={twoColumnLeftStyle}>Created At</td>
                <td style={twoColumnRightStyle}>{new Date(producerOrder.createdAt).toLocaleString()}</td>
              </tr>
              <tr>
                <td style={twoColumnLeftStyle}>Updated At</td>
                <td style={twoColumnRightStyle}>{new Date(producerOrder.updatedAt).toLocaleString()}</td>
              </tr>
            </tbody>
          </Table>

          <Table bordered hover size="sm">
            <thead>
              <tr>
                <th style={producerOrderColumnStyle}>SKU Name Ordered</th>
                <th style={producerOrderColumnStyle}>Quantity Ordered</th>
                <th style={producerOrderColumnStyle}>UOM</th>
                <th style={producerOrderColumnStyle}>Substitution Notes</th>
                <th style={producerOrderColumnStyle}>Order Notes</th>
              </tr>
            </thead>
            <tbody>
              {producerOrder.skus.map((sku, skuIndex) => (
                <tr key={skuIndex}>
                  <td style={producerOrderColumnStyle}>{sku.skuName}</td>
                  <td style={producerOrderColumnStyle}>{sku.quantity}</td>
                  <td style={producerOrderColumnStyle}>{sku.uom}</td>
                  <td style={producerOrderColumnStyle}>{sku.substitutionNotes || 'N/A'}</td>
                  <td style={producerOrderColumnStyle}>{producerOrder.orderNotes || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Table bordered hover size="sm">
            <thead>
              <tr>
                <th style={producerOrderColumnStyle}>SKU Name Packed</th>
                <th style={producerOrderColumnStyle}>Quantity Packed</th>
                <th style={producerOrderColumnStyle}>UOM</th>
                <th style={producerOrderColumnStyle}></th>
                <th style={producerOrderColumnStyle}></th>
              </tr>
            </thead>
            <tbody>
              {producerOrder.skus.map((sku, skuIndex) => (
                <tr key={skuIndex}>
                  <td style={producerOrderColumnStyle}>{sku.skuNameOrdered || 'N/A'}</td>
                  <td style={producerOrderColumnStyle}>{sku.quantityPacked}</td>
                  <td style={producerOrderColumnStyle}>{sku.uom}</td>
                  <td style={producerOrderColumnStyle}></td>
                  <td style={producerOrderColumnStyle}></td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Producer Order Dates */}
          <Table bordered hover size="sm">
            <tbody>
              <tr>
                <td style={twoColumnLeftStyle}>Substitutions required</td>
                <td style={twoColumnRightStyle}>{producerOrder.substutionsRequired || 'N/A'}</td>
              </tr>
              <tr>
                <td style={twoColumnLeftStyle}>Created At</td>
                <td style={twoColumnRightStyle}>{new Date(producerOrder.createdAt).toLocaleString()}</td>
              </tr>
              <tr>
                <td style={twoColumnLeftStyle}>Updated At</td>
                <td style={twoColumnRightStyle}>{new Date(producerOrder.updatedAt).toLocaleString()}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      ))}
    </div>
  );
};

export default OrderDetailsTable;
