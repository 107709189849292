// src/services/skuService.js

import { httpsCallable } from "firebase/functions";
//import { getFunctions, httpsCallable } from "firebase/functions";
import { functions } from "../firebaseConfig";  // Assuming you have a firebase.js file where you initialize Firebase

export const getSkus = async () => {
  const getSkusFull = httpsCallable(functions, 'getSkusFull');
  const response = await getSkusFull();
  const skus = response.data.skus;
  return skus;
};

export const getSkuById = async (skuId) => {
  const getSkuByIdFull = httpsCallable(functions, 'getSkuByIdFull');
  const response = await getSkuByIdFull({ skuId });
  return response.data;
};

export const updateSku = async (skuId, skuData) => {
  const updateSkuVX = httpsCallable(functions, 'updateSkuVX');
  const response = await updateSkuVX({ skuId, ...skuData });
  return response.data;
};
