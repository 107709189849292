import React, { useEffect, useState } from 'react';
import { Container, Table, FormControl, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getAllRegions } from '../../services/regionService';  // Import region service
import '../../styles.css';

const Regions = () => {
  const [regionsData, setRegionsData] = useState([]);
  const [filteredRegions, setFilteredRegions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRegionsData = async () => {
      try {
        const data = await getAllRegions();
        if (data.regions && Array.isArray(data.regions)) {
          setRegionsData(data.regions);  // Ensure the data is an array
          setFilteredRegions(data.regions);
        } else {
          setError('Unexpected data format');
        }
      } catch (error) {
        console.error('Error fetching regions data:', error);
        setError('Error fetching regions data');
      } finally {
        setLoading(false);
      }
    };

    fetchRegionsData();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const results = regionsData.filter((region) =>
        Object.values(region).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredRegions(results);
    } else {
      setFilteredRegions(regionsData);
    }
  }, [searchTerm, regionsData]);

  const handleRowClick = (regionId) => {
    navigate(`/region-details?regionId=${regionId}`);
  };

  if (loading) {
    return (
      <Container className="content d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return <Container className="content"><p>{error}</p></Container>;
  }

  return (
    <Container className="content">
      <h1>Regions Page</h1>
      <div className="d-flex mb-3">
        <FormControl
          type="text"
          placeholder="Search"
          className="me-2"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Region Name</th>
            <th>State</th>
            <th>Country</th>
            <th>Short Description</th>
          </tr>
        </thead>
        <tbody>
          {filteredRegions.map((region) => (
            <tr
              key={region.regionId}
              onClick={() => handleRowClick(region.regionId)}
              style={{ cursor: 'pointer' }}
            >
              <td>{region.regionName}</td>
              <td>{region.stateName}</td>
              <td>{region.country}</td>
              <td>{region.shortDescription}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Regions;
