import React from 'react';
import { FormControl } from 'react-bootstrap';
import Select from 'react-select';
import options from '../constants/skuOptions'; // Predefined options like before

const InputFieldRenderer = ({ field, value, category, handleInputChange, isEditing, sellers = [], regions = [], species = [] }) => {

  // Create simplified arrays of { value, label } for sellers, regions, and species
  const sellerOptions = Array.isArray(sellers) ? sellers.map((seller) => ({
    value: seller.sellerId,
    label: seller.sellerName,
  })) : [];

  const regionOptions = Array.isArray(regions) ? regions.map((region) => ({
    value: region.regionId,
    label: region.regionName,
  })) : [];

  const speciesOptions = Array.isArray(species) ? species.map((specie) => ({
    value: specie.speciesId,
    label: specie.speciesBreed,
  })) : [];

  // Define special handling for fields that require fetching from external data sources
  if (field === 'sellerId') {
    // Render dropdown for seller selection
    return (
      <Select
        options={sellerOptions}
        value={sellerOptions.find((option) => option.value === value) || null}
        onChange={(selectedOption) => {
          handleInputChange(category, field, selectedOption ? selectedOption.value : '');
        }}
        isDisabled={!isEditing}
        styles={{ control: (base) => ({ ...base, fontSize: '10pt' }) }}
      />
    );
  }

  if (field === 'sellerRegionId') {
    // Render dropdown for region selection
    return (
      <Select
        options={regionOptions}
        value={regionOptions.find((option) => option.value === value) || null}
        onChange={(selectedOption) => {
          handleInputChange(category, field, selectedOption ? selectedOption.value : '');
        }}
        isDisabled={!isEditing}
        styles={{ control: (base) => ({ ...base, fontSize: '10pt' }) }}
      />
    );
  }

  if (field === 'speciesId') {
    // Render dropdown for species selection
    return (
      <Select
        options={speciesOptions}
        value={speciesOptions.find((option) => option.value === value) || null}
        onChange={(selectedOption) => {
          handleInputChange(category, field, selectedOption ? selectedOption.value : '');
        }}
        isDisabled={!isEditing}
        styles={{ control: (base) => ({ ...base, fontSize: '10pt' }) }}
      />
    );
  }

  // Check if the field has predefined options from the options file
  if (options[field]) {
    return (
      <Select
        options={options[field]}
        value={options[field].find((option) => option.value === value)}
        onChange={(selectedOption) => {
          handleInputChange(category, field, selectedOption ? selectedOption.value : '');
        }}
        isDisabled={!isEditing}
        styles={{ control: (base) => ({ ...base, fontSize: '10pt' }) }}
      />
    );
  }

  // Render a text input for other fields
  return (
    <FormControl
      type="text"
      value={value || ''}
      disabled={!isEditing}
      onChange={(e) => {
        handleInputChange(category, field, e.target.value);
      }}
      style={{ fontSize: '10pt' }}
    />
  );
};

export default InputFieldRenderer;
