import React, { useEffect, useRef } from "react";
import { Nav, Navbar as BootstrapNavbar, Container, Dropdown, Offcanvas } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { FaUser, FaCog, FaAdjust } from "react-icons/fa";
import { BsFillSunFill, BsFillMoonStarsFill, BsCheckLg } from "react-icons/bs";
import { setDarkMode } from "../themeUtils";
import { Offcanvas as BootstrapOffcanvas } from "bootstrap";

import "../styles.css";

const Navbar = ({ theme, setTheme }) => {
    const navigate = useNavigate();
    const offcanvasRef = useRef(null);

    useEffect(() => {
        setDarkMode(theme);
    }, [theme]);

    const handleLogout = () => {
        signOut(getAuth()).then(() => {
            navigate("/logging-out");
        });
    };

    const handleThemeChange = (newTheme) => {
        setTheme(newTheme);
    };

    const renderThemeIcon = () => {
        switch (theme) {
            case "dark":
                return <BsFillMoonStarsFill />;
            case "light":
                return <BsFillSunFill />;
            case "auto":
                return <FaAdjust />;
            default:
                return <BsFillSunFill />;
        }
    };

    const logoSrc = theme === "dark" ? "/hs-light-text.png" : "/hs-dark-text.png";

    const handleLinkClick = () => {
        if (offcanvasRef.current) {
            const offcanvasElement = offcanvasRef.current;
            const offcanvasInstance = BootstrapOffcanvas.getInstance(offcanvasElement);
            offcanvasInstance?.hide();
        }
    };

    return (
        <BootstrapNavbar
            bg={theme === "dark" ? "dark" : "light"}
            expand="lg"
            fixed="top"
            variant={theme === "dark" ? "dark" : "light"}
        >
            <Container>
                <BootstrapNavbar.Toggle aria-controls="offcanvasNavbar" className="d-lg-none" />
                <BootstrapNavbar.Brand>
                    <img src={logoSrc} alt="Harvest Stack Console" height="50" className="me-3" />
                </BootstrapNavbar.Brand>
                <BootstrapNavbar.Offcanvas
                    id="offcanvasNavbar"
                    aria-labelledby="offcanvasNavbarLabel"
                    placement="start"
                    className="d-lg-none"
                    ref={offcanvasRef}
                >
                    <Offcanvas.Header closeButton>
                        <img src={logoSrc} alt="Harvest Stack Console" height="50" className="me-3" />
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="me-auto">
                            <LinkContainer to="/" onClick={handleLinkClick}>
                                <Nav.Link>Dashboard</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/skus" onClick={handleLinkClick}>
                                <Nav.Link>SKUs</Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="/orders" onClick={handleLinkClick}>
                                <Nav.Link>Orders</Nav.Link>
                            </LinkContainer>

                            {/* <LinkContainer to="/sellers" onClick={handleLinkClick}>
                  <Nav.Link>Sellers</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/seller-orders" onClick={handleLinkClick}>
                <Nav.Link>Seller Orders</Nav.Link>
              </LinkContainer> */}

                            <Dropdown>
                                <Dropdown.Toggle variant="link" className="nav-link">
                                    Buyers
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <LinkContainer to="/create-buyer" onClick={handleLinkClick}>
                                        <Dropdown.Item>Create</Dropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/buyers" onClick={handleLinkClick}>
                                        <Dropdown.Item>Manage</Dropdown.Item>
                                    </LinkContainer>
                                </Dropdown.Menu>
                            </Dropdown>

                            <LinkContainer to="/sellers" onClick={handleLinkClick}>
                                <Nav.Link>Sellers</Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="/species" onClick={handleLinkClick}>
                                <Nav.Link>Species</Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="/regions" onClick={handleLinkClick}>
                                <Nav.Link>Regions</Nav.Link>
                            </LinkContainer>

                            <Dropdown>
                                <Dropdown.Toggle variant="link" className="nav-link">
                                    Users
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <LinkContainer to="/create-user" onClick={handleLinkClick}>
                                        <Dropdown.Item>Create</Dropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/users" onClick={handleLinkClick}>
                                        <Dropdown.Item>Manage</Dropdown.Item>
                                    </LinkContainer>
                                </Dropdown.Menu>
                            </Dropdown>

                            <hr />
                            <LinkContainer to="/console-admin" onClick={handleLinkClick}>
                                <Nav.Link>Console Admin</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/profile" onClick={handleLinkClick}>
                                <Nav.Link>Profile</Nav.Link>
                            </LinkContainer>
                            <Nav.Item>
                                <Nav.Link onClick={handleLogout}>Sign out</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <hr />
                        <Nav>
                            <Dropdown>
                                <Dropdown.Toggle className="no-border" variant="outline-secondary" id="theme-dropdown">
                                    {renderThemeIcon()}&nbsp;&nbsp;Toggle theme
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleThemeChange("light")}>
                                        <BsFillSunFill className="me-2" /> Light{" "}
                                        {theme === "light" && <BsCheckLg className="checkmark mt-1" />}
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleThemeChange("dark")}>
                                        <BsFillMoonStarsFill className="me-2" /> Dark{" "}
                                        {theme === "dark" && <BsCheckLg className="checkmark mt-1" />}
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleThemeChange("auto")}>
                                        <FaAdjust className="me-2" /> Auto{" "}
                                        {theme === "auto" && <BsCheckLg className="checkmark mt-1" />}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Offcanvas.Body>
                </BootstrapNavbar.Offcanvas>
                <BootstrapNavbar.Collapse id="basic-navbar-nav" className="desktop-nav">
                    <Nav className="me-auto">
                        <LinkContainer to="/">
                            <Nav.Link>Dashboard</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/skus">
                            <Nav.Link>SKUs</Nav.Link>
                        </LinkContainer>

                        <LinkContainer to="/orders">
                            <Nav.Link>Orders</Nav.Link>
                        </LinkContainer>

                        {/* <LinkContainer to="/sellers">
                <Nav.Link>Sellers</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/seller-orders">
                <Nav.Link>Seller Orders</Nav.Link>
              </LinkContainer> */}

                        <Dropdown>
                            <Dropdown.Toggle variant="link" className="nav-link">
                                Buyers
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <LinkContainer to="/create-buyer">
                                    <Dropdown.Item>Create</Dropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/buyers">
                                    <Dropdown.Item>Manage</Dropdown.Item>
                                </LinkContainer>
                            </Dropdown.Menu>
                        </Dropdown>

                        <LinkContainer to="/sellers" onClick={handleLinkClick}>
                            <Nav.Link>Sellers</Nav.Link>
                        </LinkContainer>

                        <LinkContainer to="/species" onClick={handleLinkClick}>
                            <Nav.Link>Species</Nav.Link>
                        </LinkContainer>

                        <LinkContainer to="/regions" onClick={handleLinkClick}>
                            <Nav.Link>Regions</Nav.Link>
                        </LinkContainer>

                        <Dropdown>
                            <Dropdown.Toggle variant="link" className="nav-link">
                                Users
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <LinkContainer to="/create-user">
                                    <Dropdown.Item>Create</Dropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/users">
                                    <Dropdown.Item>Manage</Dropdown.Item>
                                </LinkContainer>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                    <Nav>
                        <Dropdown>
                            <Dropdown.Toggle className="no-border" variant="outline-secondary" id="theme-dropdown">
                                {renderThemeIcon()}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleThemeChange("light")}>
                                    <BsFillSunFill className="me-2" /> Light{" "}
                                    {theme === "light" && <BsCheckLg className="checkmark mt-1" />}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleThemeChange("dark")}>
                                    <BsFillMoonStarsFill className="me-2" /> Dark{" "}
                                    {theme === "dark" && <BsCheckLg className="checkmark mt-1" />}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleThemeChange("auto")}>
                                    <FaAdjust className="me-2" /> Auto{" "}
                                    {theme === "auto" && <BsCheckLg className="checkmark mt-1" />}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown className="ms-2">
                            <Dropdown.Toggle className="no-border" variant="outline-secondary" id="cog-dropdown">
                                <FaCog />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <LinkContainer to="/console-admin">
                                    <Dropdown.Item>Console Admin</Dropdown.Item>
                                </LinkContainer>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown.Divider />
                        <Dropdown className="ms-2">
                            <Dropdown.Toggle className="no-border" variant="outline-secondary" id="user-dropdown">
                                <FaUser />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <LinkContainer to="/profile">
                                    <Dropdown.Item>Profile</Dropdown.Item>
                                </LinkContainer>
                                <Dropdown.Item onClick={handleLogout}>Sign out</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </BootstrapNavbar.Collapse>
            </Container>
        </BootstrapNavbar>
    );
};

export default Navbar;
