import React, { useEffect, useState } from 'react';
import { Container, Table, FormControl, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate to handle navigation
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebaseConfig';  // Import Firebase functions
import '../../styles.css';

const Sellers = () => {
  const [sellersData, setSellersData] = useState([]);
  const [filteredSellers, setFilteredSellers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate function

  useEffect(() => {
    const fetchSellersData = async () => {
      const getAllSellers = httpsCallable(functions, 'getAllSellers');

      try {
        const result = await getAllSellers();
        if (result.data.sellers && Array.isArray(result.data.sellers)) {
          setSellersData(result.data.sellers);  // Ensure the data is an array
          setFilteredSellers(result.data.sellers);
        } else {
          setError('Unexpected data format');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching sellers data:', error);
        setError('Error fetching sellers data');
        setLoading(false);
      }
    };

    fetchSellersData();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const results = sellersData.filter((seller) =>
        Object.values(seller).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredSellers(results);
    } else {
      setFilteredSellers(sellersData);
    }
  }, [searchTerm, sellersData]);

  const handleRowClick = (sellerId) => {
    navigate(`/seller-details?sellerId=${sellerId}`);
  };

  if (loading) {
    return (
      <Container className="content d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return <Container className="content"><p>{error}</p></Container>;
  }

  return (
    <Container className="content">
      <h1>Sellers Page</h1>
      <div className="d-flex mb-3">
        <FormControl
          type="text"
          placeholder="Search"
          className="me-2"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Seller Name</th>
            <th>Seller Code</th>
            <th>State</th>
            <th>Country</th>
          </tr>
        </thead>
        <tbody>
          {filteredSellers.map((seller) => (
            <tr key={seller.sellerId} onClick={() => handleRowClick(seller.sellerId)} style={{ cursor: 'pointer' }}>
              <td>{seller.sellerName}</td>
              <td>{seller.sellerCode}</td>
              <td>{seller.sellerState}</td>
              <td>{seller.sellerCountry}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Sellers;
