import React, { useState, useCallback } from "react";
import { getSkuById } from "../services/skuService";
import SkuDetailsModal from "./SkuDetailsModal";
import ProducerOrderCard from "./ProducerOrderCard"; // Import the new component

const ProducerOrderComponent = ({ producerOrders, skus, isEditing, handleInputChange, orderSummary }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedSku, setSelectedSku] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    //const [packedDetails, setPackedDetails] = useState({});

    // Using useCallback to memoize the function and prevent unnecessary re-renders
    const handleSkuClick = useCallback(async (skuId) => {
        setShowModal(true);
        setLoading(true);
        setError(null);
        try {
            const skuDetails = await getSkuById(skuId);
            setSelectedSku(skuDetails);
        } catch (err) {
            console.error("Error fetching SKU details:", err);
            setError("Failed to load SKU details. Please try again.");
        } finally {
            setLoading(false);
        }
    }, []);

    return (
        <>
            {producerOrders?.map((order, index) => (
                <ProducerOrderCard
                    key={index}
                    order={order}
                    handleSkuClick={handleSkuClick}
                    //packedDetails={packedDetails}
                    skus={skus}
                    isEditing={isEditing} // Pass down isEditing state
                />
            ))}

            <SkuDetailsModal
                showModal={showModal}
                setShowModal={setShowModal}
                loading={loading}
                error={error}
                selectedSku={selectedSku}
            />
        </>
    );
};

export default ProducerOrderComponent;
