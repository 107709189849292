import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './InvoiceModal.css'; // Ensure you have appropriate styles for the invoice layout

const InvoiceModal = ({ show, handleClose, finalOrderSummary }) => {
  if (!finalOrderSummary) return null;

  // Extract necessary data from finalOrderSummary
  const { cartItems, orderTotal, totalItemCost } = finalOrderSummary;

  return (
    <Modal show={show} onHide={handleClose} size="lg" dialogClassName="invoice-modal">
      <Modal.Header closeButton>
        <Modal.Title>Tax Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="invoice-container">
          {/* Header section with company info */}
          <div className="invoice-header">
            <div className="invoice-title">TAX INVOICE</div>
            <div className="company-info">
              <strong>Harvest Stack Australia Pty Ltd</strong><br />
              28 Alexandra Street<br />
              ST KILDA EAST VIC 3183<br />
              AUSTRALIA<br />
              ABN: 35667409181
            </div>
            <div className="invoice-meta">
              <div>Invoice Date: [Invoice Date]</div>
              <div>Invoice Number: [Invoice Number]</div>
              <div>ABN: [Customer ABN]</div>
              <div>
                {cartItems && cartItems.length > 0 && (
                  <>
                    {cartItems[0].sellerName}<br />
                    {[cartItems[0].sellerId]}<br />
                    {/* Replace above with actual address information */}
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Line items section */}
          <table className="invoice-table">
            <thead>
              <tr>
                <th>Description</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>GST</th>
                <th>Amount AUD</th>
              </tr>
            </thead>
            <tbody>
              {cartItems && cartItems.map((sellerGroup, index) => (
                <React.Fragment key={index}>
                  <tr className="seller-row">
                    <td colSpan="5"><strong>Invoiced on behalf of {sellerGroup.sellerName}</strong></td>
                  </tr>
                  {sellerGroup.items.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item.speciesBreed}</td>
                      <td>{item.quantity}</td>
                      <td>${item.unitPrice.toFixed(2)}</td>
                      <td>10%</td> {/* Assuming GST is 10% */}
                      <td>${item.total.toFixed(2)}</td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>

          {/* Totals and payment details */}
          <div className="invoice-summary">
            <div className="totals">
              <div>Subtotal: ${totalItemCost.toFixed(2)}</div>
              <div>Total GST 10%: ${(totalItemCost * 0.1).toFixed(2)}</div>
              <div><strong>Total AUD: ${orderTotal.toFixed(2)}</strong></div>
            </div>
            <div className="payment-details">
              <div>Due Date: [Due Date]</div>
              <div>AUD Payment Details:</div>
              <div>Account number: 7674 7254</div>
              <div>BSB: 062 692</div>
            </div>
          </div>

          {/* Payment advice section */}
          <div className="payment-advice">
            <div className="advice-title">PAYMENT ADVICE</div>
            <div className="advice-details">
              <div>To:</div>
              <div>[Customer Name]</div>
              <div>[Customer Address]</div>
              <div>[Customer City]</div>
              <div>[Customer Country]</div>
            </div>
            <div className="advice-summary">
              <div>Customer: Harvest Stack Australia Pty Ltd</div>
              <div>Invoice Number: [Invoice Number]</div>
              <div>Amount Due: ${orderTotal.toFixed(2)}</div>
              <div>Due Date: [Due Date]</div>
              <div>Amount Enclosed: __________</div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvoiceModal;
